#liste{
  display: none;
  }
  
  #backImg {
    border-radius: 10px;
 
  color: rgb(249, 245, 18);
  max-height: 200px;
  
     
    }
  
    #arab{
      font-family: 'Cairo', sans-serif;
      border-radius: 10px;
  }
  #arab11{
    font-family: 'Cairo', sans-serif;
    border-radius: 10px;
    position: relative;
  top: 50px;
  left: -100px;
  }
  #arab2{
    font-family: 'Tajawal', sans-serif;
    font-weight: 900;
    
  
  }
  #arab2:hover{
  
  
    border-radius: 9px;
    background-color: rgb(6, 81, 243);
    box-shadow: 0px 0px 10px 5px rgba(50, 17, 235, 0.75);
  }
  
  
  
  #arab3{
    font-family: 'Cairo', sans-serif;
  }
  #arab4{
    font-family: 'Cairo', sans-serif;
  }
  #admin{
    border-radius: 100px;
    height: 50px;
    width: 100px;
  margin-right: -15px;
  background-image: url('./img/1\ \(1\).png');
  background-repeat: no-repeat;
  background-size: contain;
  font-size: 40px;
  font-family: 'Tajawal', sans-serif;
  border-bottom: none;
  text-decoration: none;
  position: relative;
  left: 100px;
  top: 7px;
  
  }
  #yy{
    background-image: url('./img/1\ \(251\).jpg'); 
    background-size: contain;
    background-repeat: no-repeat;
  }
  .xx{
    position: relative;
    left: 7px;
    top: 10px;

   
  }
  #mansh{
    position: relative;
    left: 6px;
  }
  