@import url(https://fonts.googleapis.com/css2?family=Tajawal&display=swap);
.header {
  margin-top: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.headerTitles {
  font-family: "Lora", serif;
  color: #444;
}

.headerTitleSm {
  position:relative;

 color: rgb(255, 0, 0);
  bottom: 80px;
  right: 90px;
 
}

.headerTitleLg {
  position:relative;
bottom: 40px;
  font-size: 30px;
left: 45px;
color: rgb(10, 10, 2);

}

.headerImgContainer {
  width: 100%;
  padding-bottom: 40px;
margin-top: -37px;
}

.headerImg {
  width: 100%;
}

@media (max-width: 600px) {
  .headerImgContainer {
    width: 100%;
  }
}
#arab99{
  font-family: 'Tajawal', sans-serif;
}
#liste{
  display: none;
  }
  
  #backImg {
    border-radius: 10px;
 
  color: rgb(249, 245, 18);
  max-height: 200px;
  
     
    }
  
    #arab{
      font-family: 'Cairo', sans-serif;
      border-radius: 10px;
  }
  #arab11{
    font-family: 'Cairo', sans-serif;
    border-radius: 10px;
    position: relative;
  top: 50px;
  left: -100px;
  }
  #arab2{
    font-family: 'Tajawal', sans-serif;
    font-weight: 900;
    
  
  }
  #arab2:hover{
  
  
    border-radius: 9px;
    background-color: rgb(6, 81, 243);
    box-shadow: 0px 0px 10px 5px rgba(50, 17, 235, 0.75);
  }
  
  
  
  #arab3{
    font-family: 'Cairo', sans-serif;
  }
  #arab4{
    font-family: 'Cairo', sans-serif;
  }
  #admin{
    border-radius: 100px;
    height: 50px;
    width: 100px;
  margin-right: -15px;
  background-image: url("/static/media/1 (1).b4532afa.png");
  background-repeat: no-repeat;
  background-size: contain;
  font-size: 40px;
  font-family: 'Tajawal', sans-serif;
  border-bottom: none;
  text-decoration: none;
  position: relative;
  left: 100px;
  top: 7px;
  
  }
  #yy{
    background-image: url("/static/media/1 (251).039bf2eb.jpg"); 
    background-size: contain;
    background-repeat: no-repeat;
  }
  .xx{
    position: relative;
    left: 7px;
    top: 10px;

   
  }
  #mansh{
    position: relative;
    left: 6px;
  }
  
#rtl {
    direction: rtl;
 
  }
  #rtl1 {
    direction: rtl;

  }
  #rtl2 {
    direction: rtl;
 
  }
  #rtl3  {
    direction: rtl;
 
  }
  #rtl4 {
    direction: rtl;
 
  }
  #rtl5 {
    direction: rtl;
 
  }
  #rtl6 {
    direction: rtl;
 
  }
  #rtl0 {
  visibility: hidden;
 
  }


  #arab{
    font-family: 'Cairo', sans-serif;
}
#arab1{
  font-family: 'Cairo', sans-serif;
}
#arab79{
  font-family: 'Tajawal', sans-serif;



 
}



#arab{
   /* font-family: 'Cairo', sans-serif; */
   font-family: 'Tajawal', sans-serif;
}

.input{
     direction: 'rtl'
}

#arab100{
    font-family: 'Tajawal', sans-serif; 
}
.category {
  margin-top: 1px;
  position: relative;
  bottom: 20px;

}
.category .box {
  border-radius: 0;
  text-align: center;
}
.category p {
  text-transform: capitalize;
  font-size: 14px;
  font-weight: 600;
}
.category img {
  width: 100%;
  height: 500px;
  object-fit: cover;
}
.category .box::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 500px;
  background: rgba(0, 0, 0, 0.1);
}



.category .box {
  cursor: pointer;
  position: relative;
}
.category .overlay {
  position: absolute;
  top: 350px;
  padding: 20px;
  text-align: center;
  width: 100%;
  z-index: 2;
  color: #fff;
}
.category .overlay h4 {
  font-weight: 500;
  color: rgb(253, 246, 126);
}
.category .overlay p {
  color: #fff;
  font-weight: 300;
  padding: 15px;
}
/*---------------control-btn-------------*/
.control-btn button {
  background: white;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  cursor: pointer;
  box-shadow: 0 3px 6px rgb(0 0 0 / 16%);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.5s;
  padding: 2px;
}

.control-btn .next {
  position: absolute;
  top: 40%;
  right: 20px;
}

.control-btn .prev {
  position: absolute;
  top: 40%;
  left: 20px;
  z-index: 99;
}
.control-btn .icon {
  font-size: 100px;
}
/*---------------control-btn-------------*/
@media only screen and (max-width: 600px) {
  .category img {
    width: 100%;
    height: 300px; /* adjust as needed */
  }
}

/* #red{
    

    */
    /* #wid{
     
    }

    #ww{
       
    } */

    .customers{
      width: 100%;
  }

  .table-header {
      background-color: #f2f2f2;
      color: #000;
      font-weight: bold;
    }
    
    .table-row {
      border-bottom: 1px solid #ddd;
    }
    
    .table-row:hover {
      background-color: #f5f5f5;
    }
    
  
@font-face {
    font-family: 'Cairo';
    src: url(/static/media/Cairo-Black.2374c281.ttf) format('truetype');
}

body {
  font-family: 'Cairo', sans-serif;

}

