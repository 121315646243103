/* #red{
    

    */
    /* #wid{
     
    }

    #ww{
       
    } */

    .customers{
      width: 100%;
  }

  .table-header {
      background-color: #f2f2f2;
      color: #000;
      font-weight: bold;
    }
    
    .table-row {
      border-bottom: 1px solid #ddd;
    }
    
    .table-row:hover {
      background-color: #f5f5f5;
    }
    
  